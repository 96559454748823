import styled from 'styled-components';
import { Colors } from '../../Theme/Colors';
import  CampainReportSubHeader   from '../../View/CampainReportSubHeader/index';
import Header from '../../View/Header/index';
const Container = styled.div`
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const Th = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  ont-weight:medium;
font-size:16px;
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  min-width:100px;
  font: normal normal normal 14px/18px;
`;

const ClicksTable = styled.div`
  margin-top: 20px;
`;

const BackgroundView = styled.div`
  margin-top: 100px;
`;



export {
  Container,
  Table,
  Th,
  Td,
  ClicksTable,
  CampainReportSubHeader,
  BackgroundView,
  Header
}