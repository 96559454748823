import styled from 'styled-components';
import { Colors } from '../../Theme/Colors';


const TopBox = styled.div`
  background-color: ${Colors.transparent};
  height: 90px;
  width:100%;
  display: flex;
  margin-left:0px;
  justify-content: start;
  padding: 0px 0px;
  align-items: center;
`;
const FlexDiv = styled.div`
 flex:1;
`;
const Title = styled.h1`
   display: flex;
   font-weight:bold;
font-size:26px;
`;
const CreateCampaignButton = styled.button`
  display: block;
  padding: 10px 20px;
 background: #262261 0% 0% no-repeat padding-box;
border-radius: 100px;
 opacity: 1;
  color: white;
  border: none;
  height: 60px;
  cursor: pointer;
  justify-content: flex-end;

`;
const RefreshButton = styled.button`
  display: block;
  padding: 10px 20px;
  margin-left:20px;
 background: transparent 0% 0% no-repeat padding-box;
border-radius: 100px;
 opacity: 1;
  color: white;
  border: none;
  height: 60px;
  cursor: pointer;
  color: #262261; 
  justify-content: flex-end;
  font-weight:bold;
font-size:16px;

`;


export {
  TopBox,
  Title,
  CreateCampaignButton,
  FlexDiv,
  RefreshButton
  
}