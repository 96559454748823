
import { 
  TopBox,
  Title,
  CreateCampaignButton,
 } from './style';
const MyCampaignBox = (props) => {
  
  return (
    <TopBox>
       <Title>{props.title}</Title>
       <CreateCampaignButton onClick={() =>{
       if(props.onButtonClick) props.onButtonClick()
       }}>{props.buttonTitle}</CreateCampaignButton>
       
  
    </TopBox>
  );
};

//Styled-Components


export default MyCampaignBox;
