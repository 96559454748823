import styled from 'styled-components';
import CrossButtonWithText from '../CrossButtonWithText/index'

const FormGroup = styled.div`
border-radius: 4px;
height:60px;
flex-flow: column nowrap;
flex-direction: column;
 display: flex;
opacity: 1;
`;
const RowGroup = styled.div`
border-radius: 4px;
height:60px;
flex-flow: column nowrap;
flex-direction: row;
 display: flex;
opacity: 1;
`;
const TopBox = styled.div`
  display: flex;
  justify-content: start;
  padding-left: 20px;
   padding-right: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #BABABA;
border-radius: 4px;
`;
const FlexView = styled.div`
flex:1;
`;
const Title = styled.label`
font: normal normal bold 16px/21px;
font-weight:bold;
letter-spacing: 0px;
color: #000000;
margin-top:10px;
margin-bottom:5px;
opacity: 1;
`;
const SubTitle = styled.label`
text-align: left;
font: normal normal normal 14px/18px;
letter-spacing: 0px;
font-size:12px;
color: #000000;
opacity: 1;
`;
const UploadButton = styled.button`
 background: none;
  text-align: left;
font: normal normal bold 20px/25px;
font-weight:bold;
font-size:16px;
letter-spacing: 0px;
color: #262261;
opacity: 1;
  border: none;
  color: #4A4A8B;
  cursor: pointer;

`;

export {
  FormGroup,
  TopBox,
  FlexView,
  Title,
  SubTitle,
  UploadButton,
  RowGroup,
  CrossButtonWithText
}