import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import {
  Container,
  LoginBox,
  Title,
  Form,
  Input,
  Button,
  ErrorMessage,
  Label,
  Header
} from "./style";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { effectsUser } from "../../Slice/userSlice";
import { Rings } from "react-loader-spinner";
import { apis } from "../../Constants/URL";
import { notify } from "../../shared/toast";
import {APIHelper} from "../../utils/APIHelper";
import { validate } from "../../shared/validate";
import { Logger } from "../../utils/Logger";
const Login = () => {
  const dispatch = useDispatch();
  const user = useSelector((User) => User.users.user);
  const actions = effectsUser(dispatch);
  const [email, setEmail] = useState("");//shootmyemail@123//schools@gigglle.com
  const [password, setPassword] = useState("");//shootmyemail@123
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if(user){
      Logger.log('resultss ==')
      //navigate("/MyCampaigns");
    }
    return () =>{
    }
  },[])
 

  const handleSubmit = async (e) => {

    const data = {
      email: email,
      password: password
    }
    e.preventDefault();
    let error = validate(data, 'login')
    if (Object.keys(error).length) {
      setError(error);
      return;
    }
    if (password.length < 6) {
      setError("Password must be at least 6 characters");
      return;
    }
    
    loginAPICall()
    setError("");
  };
  const loginAPICall = async () => {
    let data = { email: email, password: password };
    setLoading(true);
    try {
    const result = await APIHelper.fetchRequest(apis.login, apis.postRequest,null, data);
    Logger.log('result ==',result)
    setLoading(false);
      if (result.statusCode === 200) {
        Logger.log('result ==')
        //notify("Logged successfully", "success");
        actions.setLogin(result.data);
        navigate("/MyCampaigns");
      } else {
       
        notify("invalid data", "error");
      }
    } catch (err) {
      notify("invalid data", "error");
      setLoading(false);
      setError(err.message);
    } 
  }

  return (
    <Container>
      <Header />
      {loading ?  (
            <Rings
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
            />
          ) : <LoginBox>
          <Title>Log in</Title>
          <Form onSubmit={handleSubmit}>
           
            <Label>Email : </Label>
            <Input
              type="email"
              placeholder="Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
             {error?.email && <ErrorMessage>{error?.email}</ErrorMessage>}
            <Label>Password :</Label>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
             {error?.password && <ErrorMessage>{error?.password}</ErrorMessage>}
            <Button type="submit">Log in</Button>
          </Form>
        </LoginBox>}
        <ToastContainer />
      
    </Container>
  );
};

export default Login;


