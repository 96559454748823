import React, { useEffect } from 'react';
import logo from '../../logo.svg';
import { useSelector } from "react-redux";
import {
  Container,
  Logo,
  Login,
 } from './style';
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { effectsUser } from "../../Slice/userSlice";
import { Colors } from '../../Theme/Colors';
const Header = (props) => {
  const location = useLocation();

  const user = useSelector((User) => User.users.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const actions = effectsUser(dispatch);
  useEffect(() => {
    if(!user){
      navigate('/');
    }
    return () =>{
    }
  },[]) 
  return (
    <Container>
      <Logo onClick={() =>{
        if(location.pathname !== '/MyCampaigns' ){
          if(location.pathname !== '/')
          navigate('/MyCampaigns');
        }
  
      }}>
          <img src={logo} alt="" />
      </Logo>
      {props.isLogin && 
      <div>
        <Login textColor={Colors.primaryTextColor} onClick={() =>{
           if(location.pathname !== '/MyCampaigns'){
            if(location.pathname !== '/')
            navigate('/MyCampaigns');
          }
      }}>Home</Login>
       <Login onClick={() =>{
        actions.logoutAction()
        navigate('/');
      }}>Logout</Login>
      </div>}
    </Container>
  );
};


export default Header;
