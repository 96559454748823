import React, {  useEffect,useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import CampaignList from '../../View/CampaignList/index';
import { useNavigate } from "react-router-dom";
import { 
  AppContainer,
  Main,
  MyCampaignBox,
  Header
 } from './style';
export default function MyCampaigns() {
  const navigate = useNavigate();
  return (
   
    <AppContainer>
       <Header isLogin={true} />
      
      <Main>
      <MyCampaignBox onButtonClick={() =>{
        navigate('/CreateCampaign');
      }} title={'My Campaigns'} buttonTitle={'Create New Campaign'}></MyCampaignBox>
       
       
        <CampaignList detailButtonClick={(data) =>{
          navigate('/EmailCampaignReport',{state:data});
         }} />
      </Main>
    </AppContainer>
  );
}
