
import {
  FormGroup,
  TopBox,
  FlexView,
  Title,
  SubTitle,
  UploadButton,
  RowGroup,
  CrossButtonWithText
 } from './style';
import './CreateCampain.css';
import { isEmpty } from '../../shared/validator';
const CreateCampainToView = (props) => {
  return (
    <TopBox>
      <FormGroup>
      <Title>{props.labelText}</Title>
      <SubTitle>{props.placeholder}</SubTitle>
      </FormGroup>
   <FlexView/>
   {props.file || !isEmpty(props.html) ? <CrossButtonWithText text={props.file  ? props.file.name : 'design.html'}
    onClick={()=>{
    console.log('dd')
    if(props.crossClick)props.crossClick()
  }}/> : <RowGroup>
     <div>
      <label for={props.isHtmlUpload ?"html-upload" :"file-upload"} class="custom-file-upload">
      {props.buttonText}
    </label>
<input id={props.isHtmlUpload ?"html-upload" :"file-upload"} type="file"  accept={props.fileType} onChange={props.handleFileChange} />
     </div>
     {props.isHtmlUpload  &&  <UploadButton onClick={()=>{
      if(props.buttonClick)props.buttonClick()
    }}>{props.uploadText}</UploadButton>}
    </RowGroup>}
   
 </TopBox>
 
  );
};

/*
<Input text={'ddd'} type="file" accept=".xlsx" onChange={props.handleFileChange} />
*/
//Styled-Components


export default CreateCampainToView;
