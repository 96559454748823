import { apis } from "../Constants/URL";
import {Logger} from './Logger'
export class APIHelper {
  
  static async fetchRequest(endpoint, method = 'GET', token = null,data = null, isMultipart = false) {
    const headers = {};
    if(token){
      headers['Authorization'] = "Bearer " + token
    }
    let body = null;
    //headers['Accept'] = "application/json";
    if (!isMultipart && data) {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(data);
    } else if (isMultipart && data) {
      //headers['Content-Type'] = 'multipart/form-data';
      
       body = data;
      // for (const key in data) {
      //   body.append(key, data[key]);
      // }
    }   
let fullURl =  `${apis.baseURL}${endpoint}`
Logger.log('fullURl ==',fullURl)
Logger.log('headers ==',headers)
Logger.log('data ==',data)
    try {
      const response = await fetch(fullURl, {
        method,
        headers,
        body,
      });
       
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
     // Logger.log("response ==",response.json());
      return await response.json();
    } catch (error) {
      console.error('Fetch request failed:', error);
      throw error;
    }
  }
}



