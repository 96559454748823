
import React from 'react';
import Modal from 'react-modal';
import { 
  ModalContainer,
  ButtonContainer,
  Button,
  Message
 } from './style';
 const AlertDialog = ({ isOpen, onRequestClose, onConfirm,isTitle,okTitle,cancelTitle }) => {
  return (
    <Modal style={{
      overlay: {
        position: 'fixed',
        zIndex: 1020,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(255, 255, 255, 0.75)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        background: 'white',
        width: '20rem',
        maxWidth: 'calc(100vw - 2rem)',
        maxHeight: 'calc(100vh - 2rem)',
        overflowY: 'auto',
        position: 'relative',
        border: '1px solid #ccc',
        borderRadius: '0.3rem',
      }}}  isOpen={isOpen} onRequestClose={onRequestClose} ariaHideApp={true}>
      <ModalContainer>
        <Message>{isTitle}</Message>
        <ButtonContainer>
          <Button onClick={onConfirm}>{okTitle}</Button>
          <Button onClick={onRequestClose}>{cancelTitle}</Button>
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  );
};

export default AlertDialog;
