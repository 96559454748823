import React, { useEffect,useState } from 'react';
import {CampaignListContainer,
  CampaignItem,
  CampaignInfo,
  CampaignName,
  CampaignDate,
  CheckDetailsButton,
  EmptyStateContainer,
  EmptyStateImage,
  EmptyStateText
 } from './style';
 import emptyView from '../../Constants/assests/Images/emptyView.png';
 import { Rings } from "react-loader-spinner";
import { apis } from "../../Constants/URL";
import { notify } from "../../shared/toast";
import {APIHelper} from "../../utils/APIHelper";
import Moment from 'moment';
import { Logger } from '../../utils/Logger';
import { useSelector } from "react-redux";
function CampaignList({detailButtonClick}) {
  const user = useSelector((User) => User.users.user);
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  useEffect(() => {
  campainListAPICall()
    return () =>{
    }
  },[])
  const campainListAPICall = async () => {

    setLoading(true);
    try {
      const result = await APIHelper.fetchRequest(apis.campaignlist, apis.getRequest,user.token);
      Logger.log('result ==',result)
      setLoading(false);
      if (result.statusCode === 200) {
        setCampaigns(result.data)
      } else {
        notify("invalid data", "error");
      }
    } catch (err) {
      notify("invalid data", "error");
    } 
  }
  return (
    <CampaignListContainer>
      {loading &&  (
            <Rings
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
            />
          ) }
      {campaigns.length === 0 && !loading && <EmptyStateContainer>
        <EmptyStateImage src={emptyView} alt="Nothing to show" />
        <EmptyStateText>Nothing to show here</EmptyStateText>
      </EmptyStateContainer>}

    {!loading && campaigns.map((campaign, index) => (
      <CampaignItem key={index}>
        <CampaignInfo>
          <CampaignName>{campaign.name}</CampaignName>
          <CampaignDate>{Moment(campaign.schedulled_for).format('MMM dd,yy hh:mm a')} </CampaignDate>
        </CampaignInfo>
        <CheckDetailsButton onClick={ () =>{
          detailButtonClick(campaign)
        }}>Check Details ➔</CheckDetailsButton>
      </CampaignItem>
    ))}
  </CampaignListContainer>
  );
}

export default CampaignList;
