import React, {  useEffect,useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import {useLocation} from 'react-router-dom';
import { apis } from "../../Constants/URL";
import { notify } from "../../shared/toast";
import { APIHelper } from "../../utils/APIHelper";
import { Rings } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import { 
  Container,

  Table,
  Th,
  Td,
  CampainReportSubHeader,
  BackgroundView,
  Header
 } from './style';
import { Logger } from '../../utils/Logger';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useSelector } from "react-redux";
export default function EmailCampaignReport(props) {
  const user = useSelector((User) => User.users.user);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState({});
  const [campaignURLData, setCampaignURLData] = useState([]);
  useEffect(() => {

    apiCallPromise(location.state._id)
    return () =>{
    }
  },[])
  function percentage(partialValue, totalValue) {
    if (totalValue === 0) {
      return 0; // or any value you consider appropriate, e.g., null or a specific error message
    }
    return ((100 * partialValue) / totalValue).toFixed(2);
 } 
 const apiCallPromise = async (id) => {
  setLoading(true);
  await Promise.all([
    campainListAPICall(id),
    campainURLAPICall(id),
  ]);
  setLoading(false);

 }
  const campainListAPICall = async (id) => {
    try {
      const result = await APIHelper.fetchRequest(apis.campaigndetails + id, apis.getRequest,user.token);
      Logger.log('campainListAPICall result ==',result)
      if (result.statusCode === 200) {
        setCampaignData(result.data)
        return await result.data
      } else {
        notify("invalid data", "error");
        
        return await {}
      }
    } catch (err) {
      notify("invalid data", "error");
      return await {}
    } 
  }
  const campainURLAPICall = async (id) => {
    try {
      const result = await APIHelper.fetchRequest(apis.campaignclicksbyurls + id, apis.getRequest,user.token);
      Logger.log('campainURLAPICall result ==',result)
      if (result.statusCode === 200) {
        setCampaignURLData(result.data)
        return await result.data
      } else {
        notify("invalid data", "error");
        return await {}
      }
    } catch (err) {
      notify("invalid data", "error");
      return await {}
    } 
  }

  const downloadReport = () =>{
    const workbook = XLSX.utils.book_new();

         const objSheetData = [Object.keys(campaignData), Object.values(campaignData)];
         const objSheet = XLSX.utils.aoa_to_sheet(objSheetData);
     
         // Convert array to sheet
         const arrSheet = XLSX.utils.json_to_sheet(campaignURLData);
     
         // Append sheets to workbook
         XLSX.utils.book_append_sheet(workbook, objSheet, "Email Campaign");
         XLSX.utils.book_append_sheet(workbook, arrSheet, "Campaign URL");

         const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Save the file using FileSaver
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), (campaignData?.name ? campaignData?.name :'Campain') + '.xlsx');
    notify("Download completed", "success");
  }
  return (
   
    <Container>
       <Header isLogin={true} />
       {loading ?  (
            <Rings
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
            />
          ) :
       <BackgroundView>
      <CampainReportSubHeader onButtonClick={() =>{
         downloadReport()
      }}
      onRefreshClick={() =>{
        apiCallPromise(location.state._id)
     }}
       title={campaignData?.name} refreshTitle={'Refresh'} buttonTitle={'Download Report'}></CampainReportSubHeader>
    
    
      <Table>
        <tbody>
          <tr>
            <Th>Email Campaign Report</Th>
            <Td></Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Subject Line:</Th>
            <Td>{campaignData?.subject}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Delivery Date/Time:</Th>
            <Td>{moment(campaignData?.schedulled_for).format('MMM DD,yy hh:mm a')}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th></Th>
            <Td></Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Total Recipients:</Th>
            <Td>{campaignData?.total_recipients}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Successful Deliveries:</Th>
            <Td>{campaignData?.total_delivered}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Bounces:</Th>
            <Td>{campaignData?.total_bounce +"(" + percentage((campaignData?.total_bounce), campaignData?.total_recipients) + "%)"}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Recipients Who Opened:</Th>
            <Td>{campaignData?.total_open +"(" + percentage(campaignData?.total_open, campaignData?.total_recipients) + "%)"}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Total Opens:</Th>
            <Td>{campaignData?.total_open}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Last Open Date:</Th>
            <Td>{campaignData?.last_opened ? moment(campaignData?.last_opened).format('MMM DD,yy hh:mm a') : "-"}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Recipients Who Clicked:</Th>
            <Td>{campaignData?.total_click +"(" + percentage(campaignData?.total_click, campaignData?.total_recipients) + "%)"}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Total Clicks:</Th>
            <Td>{campaignData?.total_click}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th>Last Click Date:</Th>
            <Td>{campaignData?.last_clicked ? moment(campaignData?.last_clicked).format('MMM DD,yy hh:mm a') : "-"}</Td>
            <Td></Td>
          </tr>
          <tr>
            <Th></Th>
            <Td></Td>
            <Td></Td>
          </tr>
          <tr>
           <Th>URL</Th>
            <Td>Total Clicks</Td>
            <Td>Unique Clicks</Td>
          </tr>
         {campaignURLData.map((campaign, index) => (
     <tr>
     <Th>{campaign.description}</Th>
     <Td>Total Clicks: {campaign.total_click}</Td>
     <Td>Unique Clicks: {campaign.unique_click}</Td>
   </tr>
    ))} 
        </tbody>
      </Table>

      </BackgroundView>
      }
      <ToastContainer />
    </Container>
  );
}
/*

          
          <tr>
            <Th>http://www.studentsgottalent.in/?utm_source=Best+Data+-+ISF</Th>
            <Td>Total Clicks: 146</Td>
            <Td>Unique Clicks: 104</Td>
            </tr>
          <tr>
          <Th>https://www.schoolsgottalent.in/?utm_source=Best+Data+-+ISR</Th>
          <Td>Total Clicks: 30</Td>
          <Td>Unique Clicks: 19</Td>
          </tr>
*/