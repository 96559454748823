
import { 
  TopBox,
  Title,
  CreateCampaignButton,
  FlexDiv,
  RefreshButton
 } from './style';
const CampainReportSubHeader = (props) => {
  
  return (
    <TopBox>
       <Title>{props.title}</Title>
       <FlexDiv></FlexDiv>
       <CreateCampaignButton onClick={() =>{
       if(props.onButtonClick) props.onButtonClick()
       }}>{props.buttonTitle}</CreateCampaignButton>
     {props.refreshTitle && <RefreshButton onClick={() =>{
       if(props.onRefreshClick) props.onRefreshClick()
       }}>{props.refreshTitle}</RefreshButton>}  
       
    </TopBox>
  );
};

//Styled-Components


export default CampainReportSubHeader;
