import styled from 'styled-components';
import { Colors } from '../../Theme/Colors';


const ModalContainer = styled.div`
 
  flex-direction: column;
  align-items: center;
  align-self: center;
  max-width:400px;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Message = styled.p`
  margin: 20px 0;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const Button = styled.button`
  background-color: #d9534f;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 100px;
  &:hover {
    background-color:#c9302c;
  }

  &:first-of-type {
    background-color: #2f2f7b;

    &:hover {
      background-color: #1f1f5b;
    }
  }
`;



export {
  ModalContainer,
  ButtonContainer,
  Button,
  Message
  
}