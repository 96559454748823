import styled from 'styled-components';
import { Colors } from '../../Theme/Colors';


const TopBox = styled.div`
  background-color: ${Colors.transparent};
  height: 90px;
  width:95%;
  display: flex;
  margin-left:10px;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: center;
`;
const Title = styled.h1`
   display: flex;
`;
const CreateCampaignButton = styled.button`
  display: block;
  padding: 10px 20px;
 background: #262261 0% 0% no-repeat padding-box;
border-radius: 100px;
opacity: 1;
  color: white;
  border: none;
  height: 60px;
  cursor: pointer;
  justify-content: flex-end;

`;



export {
  TopBox,
  Title,
  CreateCampaignButton,
  
}