import styled from 'styled-components';
import CreateCampainToView from '../../View/CreateCampainToView'
import Header from '../../View/Header/index';
import { Colors } from '../../Theme/Colors';
const Container = styled.div`
  padding: 20px;
  margin-top:100px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #333;
  text-align: left;
font: normal normal bold 40px/51px;
letter-spacing: 0px;
color: #000000;
opacity: 1;
`;

const FormGroup = styled.div`
margin:20px;
margin-left:0px;
background: ${Colors.transparent} 0% 0% no-repeat padding-box;
opacity: 1;
`;

const Label = styled.label`
text-align: left;
font: normal normal bold 16px/21px;
font-weight:bold;
letter-spacing: 0px;
color: #000000;
opacity: 1;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 10px;
  margin-top:10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
 font: normal normal normal 14px/18px;
 font-weight:normal;
 letter-spacing: 0px;
 color: #000000;
opacity: 1;
 &:disabled {
    background-color: #f0f0f0;
    color: #a0a0a0;
    cursor: not-allowed;
  }
  &:hover {
   border: 1px solid #ccc;
  }
`;

const Button = styled.button`
  display: inline-block;
  width: 270px;
  padding: 10px;
  margin: 10px 5px 0 0;
  font-size: 16px;
  color: ${props => props.primary ? '#FFFFFF' : '#262261'};
  background-color: ${props => props.primary ? '#262261' : '#FFFFFF'};
  border: 1px solid ${props => props.primary ? '#FFFFFF' : '#262261'};
  border-radius: 100px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.primary ? '#0056b3' : '#5a6268'};
  }
`;

const UploadButton = styled.button`
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;
const AppContainer = styled.div`
   justify-content: center;
   align-items: center;
`;
const ContainerMail = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top:-120
  height: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: #FFFFFF;
  color: #000;
  padding: 10px;
  display: flex;
  max-height: 80px;

  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
  }

  button {
  display: inline-block;
  height:40px;
  width: 270px;
  padding: 10px;
  margin: 10px 5px 0 0;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #262261;
  border: 1px solid #FFFFFF;
  border-radius: 100px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
  }
`;

export {
  AppContainer,
  Container,
  FormGroup,
  Title,
  Label,
  Input,
  Button,
  UploadButton,
  CreateCampainToView,
  Header,
  ContainerMail,
  Bar
}