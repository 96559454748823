import styled from 'styled-components';
import { Colors } from '../../Theme/Colors';


const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background 0.3s;
  
  &:hover {
    background: rgba(0, 0, 0, 0);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
  }
`;

const Cross = styled.span`
  font-size: 24px;
  line-height: 1;
  margin-right: 8px;
  color:${Colors.primaryTextColor}
`;

const ButtonText = styled.span`
  font-size: 16px;
  margin-right:10px;
  color:${Colors.primaryTextColor}
`;


export {
  Button,
  Cross,
  ButtonText
  
}