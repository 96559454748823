import { createSlice } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
const initialState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
  },
});

export const { login, logout } = userSlice.actions;
export const effectsUser = (dispatch) => {
  const { login,logout } = userSlice.actions;
  return {
    async setLogin(data) {
      dispatch(login(data));
    },
    async logoutAction() {
      dispatch(logout());
    },
   
  };
};
export default combineReducers({ users: userSlice.reducer });
