import {Routes, Route} from "react-router-dom";
import Login from './pages/Login/index'
import MyCampaigns from './pages/MyCampaigns/index'
import CreateCampaign  from "./pages/CreateCampaign";
import EmailCampaignReport from './pages/EmailCampaignReport'
import './Constants/assests/fonts/PlusJakartaSans-Regular.ttf'
import './Constants/assests/fonts/PlusJakartaSans-Bold.ttf'
import './App.css';
function App() {
  return (
    <>
     <Routes >
     <Route path="/" element={ <Login /> } />
     <Route path="/MyCampaigns" element={ <MyCampaigns /> } />
     <Route path="/CreateCampaign" element={ <CreateCampaign /> } />
     <Route path="/EmailCampaignReport" element={ <EmailCampaignReport /> } />
     </Routes>
    </>
  );
}

export default App;
