import styled from 'styled-components';

const CampaignListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CampaignItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const CampaignInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CampaignName = styled.span`
 text-align: left;
font: normal normal bold 20px/25px;
font-weight:bold;
font-size:16px;
letter-spacing: 0px;
color: #000000;
opacity: 1;
`;

const CampaignDate = styled.span`
  text-align: left;
font: normal normal normal 16px/21px;
font-weight:normal;
margin-top:10px;
font-size:14px;
letter-spacing: 0px;
color: #000000;
opacity: 1;
`;

const CheckDetailsButton = styled.button`
  background: none;
  text-align: left;
font: normal normal bold 20px/25px;
font-weight:bold;
font-size:16px;
letter-spacing: 0px;
color: #262261;
opacity: 1;
  border: none;
  color: #4A4A8B;
  cursor: pointer;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 72vh;
`;

const EmptyStateImage = styled.img`
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
`;

const EmptyStateText = styled.div`
  font-size: 18px;
  color: #777;
`;
export {
  CampaignListContainer,
  CampaignItem,
  CampaignInfo,
  CampaignName,
  CampaignDate,
  CheckDetailsButton,
  EmptyStateContainer,
  EmptyStateImage,
  EmptyStateText
}