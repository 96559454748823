export const validate = (data, type) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
     re.test(String(data.email).toLowerCase());
    const errors = {};

    if (!data.email) {
        errors.email = "Email is required";
    } else if (re.test(String(data.email))=== false) {
        errors.email = "Email is invalid";
    } else {
        delete errors.email;
    }

    if (!data.password) {
        errors.password = "Password is required";
    } else if (data.password.length < 6) {
        errors.password = "Password must be at least 6 characters";
    } else {
        delete errors.password;
    }

    if (type === "createMail") {
        delete errors.password;
        
    }

    return errors;
};
