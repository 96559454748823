/**
 *
 * URL which will be used for API calling should be declared in this file.
 */
//

export const AppInfo = {
  baseUrl: 'https://api.shootmyemail.com/',
  apiVersion:"v0/",
  apiType:"application-admin/",
  common:'common/'
};
export const apis = {
  getRequest: 'GET',
  postRequest: 'POST',
  deleteRequest: 'DELETE',
  putRequest: 'PUT',
  baseURL: AppInfo.baseUrl + AppInfo.apiVersion,
  login:AppInfo.apiType + 'login',
  campaignlist:AppInfo.common + 'campaignlist',
  addnewcampaign: AppInfo.common + 'addnewcampaign',
  campaigndetails: AppInfo.common + 'campaigndetails?_id=',
  campaignclicksbyurls: AppInfo.common + 'campaignclicksbyurls?_id='
};
