
import { 
  Button,
  Cross,
  ButtonText
 } from './style';
const CrossButtonWithText = ({ onClick, text }) => {
  
  return (
    <Button onClick={onClick}>
       <ButtonText>{text}</ButtonText>
     <Cross>&times;</Cross>
  </Button>
  );
};

//Styled-Components


export default CrossButtonWithText;
