
const Colors = {
  primary: '#F7F7F8',
  bacgroundColor:'#F4F6F8',
  primaryTextColor:'#262261',
  white: '#FFFFFF',
  lighter: '#FAFAFA',
  light: '#DAE1E7',
  dark: '#444',
  black: '#18191C',
  gray: '#878787',
  gray2: '#636366',
  gray3: '#48484a',
  gray4: '#3a3a3c',
  gray5: '#2c2c2e',
  gray6: '#1c1c1e',
  lightGray: '#8e8e93',
  lightGray2: '#aeaeb2',
  lightGray3: '#c7c7cc',
  lightGray4: '#d1d1d6',
  lightGray5: '#e1e1e1',
  giftColor: '#f2f2f7',
  gifttextColor:'#7CFF8F',
  themeBottomColor:'#262261',
  bottomColor:'#46965C',
  transparent: "transparent",
  gradientFirstcolor:'#8BE6FC',
  gradientSecondcolor:'#7CFF8F',
  gradientFirstValue:'#511D8C',
  gradientSecondValue:'#280D5E',
  progresscolor:'#B34EFF',
  textColor:'#353B36',
  buttonDisable:'rgb(0,0,0,0.3)',
  buttonFllyDisable:'rgba(52, 52, 52, 0.6)',
  eventDisable:'rgba(52, 52, 52, .8)',
  red:'red',
  buttonLiteBlue:'#67E9FF',
  shareButtonGray:'#F7F7F7',
  shareborderColor:'#E4E4E4',
  FollowButtonColor:'#26226133',
  transparentOpacity:'rgba(52, 52, 52, 0.2)',
  independenceDayColor:"#EDFFF9",
  transperentModels:'rgba(0,0,0,0.9)',
  fullTransperentModels:'rgba(0,0,0,0.2)',
  LangaugeChangeColor:'#C22723',
  firstRankColor:'#5FAD57',
  secondRankColor:'#F78055',
  thirdRankColor:'#F3C150',
  giftCountColor:'#9FA7BC',
  giftBlue:'#141DFE',
  giftAnimationColor:'#f1cbc2',
  switchActiveBackground:'rgba(124, 255, 143, 0.3)',
  switchDeActiveBackground:'rgba(255, 255, 255, 0.3)',
  challangeLeaderBoardColor:'#3D6122',
  videoColor:'#f3f6f9',
  textFieldColor:'#2C0E63',
  TutorialScreen1:'#97A1F9',
  TutorialScreen2:'#D9C7FF',
  TutorialScreen3:'#FFEFBA',
  textfieldInside:'#322D61'
};

export {Colors};
