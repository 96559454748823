import styled from 'styled-components';
import { Colors } from '../../Theme/Colors';
const Container = styled.div`
  position: fixed;
  background-color: ${Colors.white};
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  z-index: 1000;
`;

const Logo = styled.a`
  /* padding: 0; */
  width: 80px;
  /* font-size: 0; */
  /* display: inline-block; */
  align-items: center;

  a {
    cursor: auto;
    img {
      /* display: flex; */
      width: 90%;
      border-radius: 50px;
      /* align-items: center; */
    }
  }
`;


const Login = styled.a`
  background-color: rgba(0, 0, 0, 0.0);
  padding: 10px 16px;
  margin-right: 45px;
  text-transform: uppercase;
  border: 1px solid #f9f9f9;
  border-radius: 4px;
  transition: all 0.2s ease 0s;
text-align: right;
font: normal normal bold 16px/21px;
font-weight:bold;
font-size:16px;
letter-spacing: 0px;
 cursor: pointer;
color:${({ textColor }) => textColor ? textColor : '#D04040'} ;
opacity: 1;

  &:hover {
    background-color:rgba(0, 0, 0, 0.3);
    color: ${({ textColor }) => textColor ? textColor : '#D04040'} ;;
    border-color: transparent;
  }
`;

export {
  Container,
  Logo,
  Login,
}