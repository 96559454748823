import styled from 'styled-components';
import Header from "../../View/Header/index";
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const LoginBox = styled.div`
  padding: 40px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 16px;
opacity: 1;
  max-width: 400px;
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  top: 167px;
  margin-bottom: 20px;
  text-align: left;
font: normal normal bold 40px/51px;
letter-spacing: 0px;
color: #000000;
opacity: 1;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BABABA;
  border-radius: 4px;
  opacity: 1;
`;

const Button = styled.button`
  padding: 10px;
  background: #262261 0% 0% no-repeat padding-box;
 border-radius: 100px;
 opacity: 1;
  color: white;
  border: none;
  width: 160px;
  height: 60px;
  font-size: 16px;
  cursor: pointer;
    margin-top: 40px;
  &:hover {
    background: #365492;
  }
`;
const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 10px;
  color: #333;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 10px;
`;
export {
  Container,
  LoginBox,
  Title,
  Form,
  Input,
  Button,
  ErrorMessage,
  Label,
  Header
  
}