import styled from 'styled-components';
import MyCampaignBox from '../../View/MyCampaignBox/index'
import { Colors } from '../../Theme/Colors';
import Header from '../../View/Header/index';
const AppContainer = styled.div`
   justify-content: center;
   align-items: center;
`;

const Main = styled.main`
  margin-top:80px;
background-color: ${Colors.bacgroundColor};
`;

const Title = styled.h1`
  width:240px;
 background-color: red;
`;


export {
  AppContainer,
  Main,
  Title,
  MyCampaignBox,
  Header
}