import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {thunk} from 'redux-thunk';
import userReducer from '../Slice/userSlice';
const persistConfig = {
    key: 'root',
    storage,
  }
  
  const persistedReducer = persistReducer(persistConfig, userReducer)
  
  export const store = configureStore({
    reducer: persistedReducer,
  })
  export const persistor = persistStore(store)

// The store now has redux-thunk added and the Redux DevTools Extension is turned on