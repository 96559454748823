import React, { useRef, useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  AppContainer,
  Container,
  FormGroup,
  Title,
  Label,
  Input,
  Button,
  CreateCampainToView,
  Header,
  ContainerMail,
  Bar,
} from "./style";
import DateTimePicker from "react-datetime-picker";
import { useSelector } from "react-redux";
import AlertDialog from "../../View/AlertDialog";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { apis } from "../../Constants/URL";
import { notify } from "../../shared/toast";
import { APIHelper } from "../../utils/APIHelper";
import { isEmpty } from "../../shared/validator";
import { validate } from "../../shared/validate";
import { Rings } from "react-loader-spinner";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
import { Logger } from "../../utils/Logger";
var htmlValue = undefined
export default function CreateCampaign() {
  const [loading, setLoading] = useState(false);
  const [emailFile, setEmailFile] = useState(null);
  const [htmlFile, sethtmlFile] = useState(null);

  const user = useSelector((User) => User.users.user);
  const [dateTime, setDateTime] = useState(new Date());

  const [showPicker, setShowPicker] = useState(false);
  const [isMailSelected, setMailSeleted] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState(
    user?.user_info.email ? user?.user_info.email : ""
  );
  const [subject, setSubject] = useState("");
  const [campainName, setCampainName] = useState("");
  const emailEditorRef = useRef(null);
  const [preview, setPreview] = useState(true);

  const saveDesign = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.saveDesign((design) => {
      alert("Design JSON has been logged in your developer console.");
    });
  };

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { design, html } = data;
       htmlValue = html
      goBack();

    });
  };

  const goBack = () => {
    setMailSeleted(false);
  };
  const togglePreview = () => {
    const unlayer = emailEditorRef.current?.editor;

    if (preview) {
      unlayer?.hidePreview();
      setPreview(false);
    } else {
      unlayer?.showPreview("desktop");
      setPreview(true);
    }
  };

  const onDesignLoad = (data) => {
    Logger.log("onDesignLoad", data);
  };

  const onReady = (unlayer) => {
    Logger.log("onReady", unlayer);
  };
  useEffect(() => {
    return () => {};
  }, []);
  const openModal = () => {
    if (campainName.length === 0) {
      notify("Please enter campain name", "error");
      return;
    }
    if (!emailFile) {
      notify("Please add To file", "error");
      return;
    }

    const data = {
      email: email,
    };
    let error = validate(data, "createMail");
    if (Object.keys(error).length) {
      notify(error.email, "error");
      return;
    }
    if (subject.length === 0) {
      notify("Please enter subject", "error");
      return;
    }
    if (!htmlFile) {
      if(isEmpty(htmlValue)){
        notify("Please add html File", "error");
        return;
      }
     
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
    
    sendEmail();
  };
  const sendEmail = async () => {
    try {
      setLoading(true)
      var formData = new FormData();
      formData.append("name", campainName);
      formData.append("receiver_file", emailFile);
      if (htmlFile) {
        formData.append("body_file", htmlFile);
      } else {
        formData.append(
          "body_file",
          new Blob([htmlValue], { type: "text/html" })
        );
      }

      formData.append("sender_email", email);
      formData.append("subject", subject);
      const result = await APIHelper.fetchRequest(
        apis.addnewcampaign,
        apis.postRequest,
        user.token,
        formData,
        true
      );
      setLoading(false)
      Logger.log('result',result)
      if (result.statusCode === 200) {
       // notify("Send successfully", "success");
        navigate('/EmailCampaignReport',{state:result.data});
      }else{
        notify(result.message ? result.message : 'An efrror occur. Please try after sometine.', "error");
      }
      //
      //setResponse(result);
    } catch (error) {
      setLoading(false)
      console.error("Multipart request failed:", error);
    }
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };
  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    Logger.log('file ===',file)
    Logger.log('file ===',file.name)
    if (type === "Recipients") {
      setEmailFile(file);
    } else if (type === "html") {
      sethtmlFile(file);
    }
  };

  useEffect(() => {
    return () => {};
  });
  return (
    <AppContainer>
      <Header isLogin={true} />
      {loading ?  (
            <Rings
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="rings-loading"
            wrapperStyle={{}}
            wrapperClass=""
            />
          ) :
      <Container>
        <Title>Create New Campaign</Title>
        <FormGroup>
          <Label>Campaign Name</Label>
          <Input
            type="text"
            value={campainName}
            onChange={(e) => setCampainName(e.target.value)}
            placeholder="What's the name of the campaign?"
          />
        </FormGroup>
        <CreateCampainToView
          handleFileChange={(event) => {
            handleFileChange(event, "Recipients");
          }}
          crossClick={() => {
            setEmailFile(null)
          }}
          file={emailFile}
          buttonClick={() => {}}
          labelText={"To"}
          placeholder={"Who are you sending this campaign to?"}
          fileType={".xlsx"}
          buttonText={"Upload Recipients  ➔"}
        ></CreateCampainToView>
        <FormGroup>
          <Label>Who is sending this campaign</Label>
          <Input
            disabled={user?.user_info?.email ? true : false}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter email id"
          />
        </FormGroup>
        <FormGroup>
          <Label>Subject</Label>
          <Input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="What's the subject line for this campaign?"
          />
        </FormGroup>
        <CreateCampainToView
          fileType={".html"}
          isHtmlUpload={true}
          crossClick={() => {
            htmlValue = undefined
            sethtmlFile(null)
          }}
          file={htmlFile}
          html={htmlValue}
          buttonClick={() => {
            setMailSeleted(true);
          }}
          handleFileChange={(event) => {
            handleFileChange(event, "html");
          }}
          labelText={"Body"}
          placeholder={"Upload body content in HTML"}
          uploadText={"Create Email Content  ➔"}
          buttonText={"Upload Email Content ➔"}
        ></CreateCampainToView>
        <div style={{ marginTop: 38 }}>
          <Button onClick={openModal} primary>
            Send Now
          </Button>
        </div>
        {showPicker && (
          <DateTimePicker
            onChange={setDateTime}
            value={dateTime}
            isCalendarOpen={true}
            format="y-MM-dd h:mm:ss a"
          />
        )}
        <AlertDialog
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          onConfirm={handleConfirm}
          isTitle={"Are you sure you want to proceed?"}
          okTitle={"Yes"}
          cancelTitle={"No"}
        />
      </Container>
}
      <ToastContainer />
      {isMailSelected && (
        <div
          className="mailer"
          style={{
            position: "absolute",
            top: 0,
            backgroundColor: "transparent",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Container>
            <Bar>
              <button onClick={goBack}>Back</button>
              <button onClick={togglePreview}>
                {preview ? "Hide" : "Show"} Preview
              </button>
              <button onClick={exportHtml}>Export HTML</button>
            </Bar>

            <React.StrictMode>
              <EmailEditor
                ref={emailEditorRef}
                onReady={onReady}
                options={{
                  appearance: {
                    theme: "modern_light",
                  },
                }}
              />
            </React.StrictMode>
          </Container>
        </div>
      )}
    </AppContainer>
  );
}
